import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader } from 'rsuite';
import CsvUpload from '../../components/Csv/CsvUpload';
import FailureMessage from '../../components/Csv/FailureMessage';
import SuccessMessage from '../../components/Csv/SuccessMessage';
import { getSignedUrl, uploadToS3 } from '../../lib/api';

const CsvContainer = ({ password }) => {
  const [uploadAttempted, setUploadAttempted] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [batchId, setBatchId] = useState('');

  const onSubmit = async (file) => {
    setUploadAttempted(true);
    try {
      const data = await getSignedUrl(password);
      const { batchId, signedUrl } = data;

      setBatchId(batchId);

      await uploadToS3(file, signedUrl);

      setUploadSuccess(true);
    } catch (error) {
      setUploadSuccess(false);
      console.log(error);
    }
  };

  if (!uploadAttempted) return <CsvUpload onSubmit={onSubmit} />;

  if (uploadSuccess === true) return <SuccessMessage batchId={batchId} />;
  if (uploadSuccess === false) return <FailureMessage />;

  return <Loader backdrop center size="lg" content="Loading..." />;
};

CsvContainer.propTypes = {
  password: PropTypes.string.isRequired,
};

const mapStateToProps = ({ password }) => ({ password });

export default connect(mapStateToProps, null)(CsvContainer);
