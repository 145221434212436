import React from 'react';
import { Message } from 'rsuite';

const FailureMessage = () => {
  return (
    <Message
      type="error"
      title="Upload failed"
      showIcon
      style={{ marginBottom: '1%' }}
      className="alert"
    >
      File upload failed! Please reach out to support@hyperverge.co
    </Message>
  );
};

export default FailureMessage;
