import React, { useState, useEffect } from 'react';
import { Panel, Form, ButtonToolbar, Button, Message } from 'rsuite';
import PropTypes from 'prop-types';

const LoginForm = ({ onSubmit, error }) => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [formError, setFormErrors] = useState({
    emailError: false,
    passwordError: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    setButtonLoading(false);
    return () => setButtonLoading(false);
  }, [error]);

  const formErrorMessage = 'This field is required';
  const emailErrorMessage = formError.emailError ? formErrorMessage : null;
  const passwordErrorMessage = formError.passwordError
    ? formErrorMessage
    : null;

  const onFormSubmit = async () => {
    const errors = { emailError: false, passwordError: false };
    if (!credentials.email.length) errors.emailError = true;
    if (!credentials.password.length) errors.passwordError = true;
    if (errors.emailError || errors.passwordError) {
      setFormErrors(errors);
      return;
    }
    setFormErrors({ emailError: false, passwordError: false });
    setButtonLoading(true);
    await onSubmit(credentials.email.trim(), credentials.password.trim());
  };

  const loginError = error.error ? (
    <Message
      type="error"
      title="Login Error"
      showIcon
      style={{ marginBottom: '1%' }}
      className="alert"
    >
      Invalid Credentials
    </Message>
  ) : null;

  return (
    <Panel
      header={<h3 style={{ textAlign: 'center' }}>Login</h3>}
      style={{ backgroundColor: '#F7F7FA' }}
      bordered
    >
      {loginError}
      <Form
        fluid
        onChange={(e) => {
          const email = e.email ?? credentials.email;
          const password = e.password ?? credentials.password;
          setCredentials({ email, password });
        }}
      >
        <Form.Group>
          <Form.ControlLabel>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Email</span>
          </Form.ControlLabel>
          <Form.Control
            name="email"
            value={credentials.email}
            errorMessage={emailErrorMessage}
            errorPlacement="bottomEnd"
            autoComplete="on"
          />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              Password
            </span>
          </Form.ControlLabel>
          <Form.Control
            name="password"
            type="password"
            value={credentials.password}
            errorMessage={passwordErrorMessage}
            errorPlacement="bottomEnd"
            autoComplete="on"
          />
          <Form.HelpText tooltip>Required</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              block
              style={{ fontSize: '20px', fontWeight: 'bold' }}
              onClick={onFormSubmit}
              loading={buttonLoading}
            >
              Sign in
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Panel>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object).isRequired,
};

export default LoginForm;
