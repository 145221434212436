/* eslint-disable react/require-default-props */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HomeContainer from './Home';
import LoginContainer from './Login';

const App = ({ user }) => {
  if (user) return <HomeContainer />;
  return <LoginContainer />;
};

App.propTypes = {
  user: PropTypes.oneOfType([PropTypes.string]),
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, null)(App);
