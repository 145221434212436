import { LOGOUT, SET_PASSWORD } from '../lib/constants';

const initialState = '';

const passwordReducer = (password = initialState, { type, payload }) => {
  switch (type) {
    case SET_PASSWORD:
      return payload;
    case LOGOUT:
      return payload;
    default:
      return password;
  }
};

export default passwordReducer;
