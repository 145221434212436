import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import Layout from '../common/Layout';

const LoginScreen = ({ onSubmit, error }) => {
  const content = (
    <Grid style={{ marginTop: '10%' }}>
      <Row>
        <Col
          xs={22}
          xsOffset={1}
          sm={20}
          smOffset={2}
          md={18}
          mdOffset={3}
          lg={16}
          lgOffset={4}
        >
          <LoginForm onSubmit={onSubmit} error={error} />
        </Col>
      </Row>
    </Grid>
  );

  return <Layout content={content} />;
};

LoginScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object).isRequired,
};

export default LoginScreen;
