import React from 'react';
import { Grid, Row, Col, Container, Content, Header } from 'rsuite';
import PropTypes from 'prop-types';
import Navbar from '../../containers/Navbar';

const Layout = ({ content, header }) => {
  return (
    <Container style={{ marginTop: '1%' }}>
      <Header>
        <Grid fluid>
          <Row>
            <Col
              xs={24}
              sm={20}
              smOffset={2}
              md={18}
              mdOffset={3}
              lg={16}
              lgOffset={4}
            >
              <Navbar />
            </Col>
          </Row>
        </Grid>
        {header}
      </Header>
      <Content>{content}</Content>
    </Container>
  );
};

Layout.defaultProps = {
  header: null,
};

Layout.propTypes = {
  content: PropTypes.node.isRequired,
  header: PropTypes.node,
};

export default Layout;
