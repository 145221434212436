import React from 'react';
import { Navbar, Nav } from 'rsuite';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const NavbarComponent = ({ logoutHandler, user }) => {
  return (
    <Navbar>
      <Navbar.Header>
        <Nav>
          <Nav.Item>
            <img
              src="https://uploads-ssl.webflow.com/622f09894fc230ad07de84a9/6255af6b0ba2ca34da644bba_Main-Logo.png"
              alt="HyperVerge"
              height="150%"
            />
          </Nav.Item>
        </Nav>
      </Navbar.Header>
      <Navbar.Body>
        {user.length > 0 && (
          <Nav pullRight>
            <Nav.Item onSelect={logoutHandler}>
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                Logout
              </span>
            </Nav.Item>
          </Nav>
        )}
      </Navbar.Body>
    </Navbar>
  );
};

NavbarComponent.propTypes = {
  logoutHandler: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user, config }) => ({ user, config });

export default connect(mapStateToProps)(NavbarComponent);
