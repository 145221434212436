import React, { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import PropTypes from 'prop-types';
import 'react-csv-importer/dist/index.css';

const CsvUpload = ({ onSubmit }) => {
  const [csvFile, setCsvFile] = useState(null);

  return (
    <div>
      <Importer
        chunkSize={10000}
        assumeNoHeaders={false}
        restartable={false}
        onComplete={({ file }) => {
          setCsvFile(file);
        }}
        onClose={async () => {
          await onSubmit(csvFile);
        }}
      >
        <ImporterField name="name" label="name" />
        <ImporterField name="transactionId" label="transactionId" />
        <ImporterField name="customerType" label="customerType" />
      </Importer>
    </div>
  );
};

CsvUpload.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CsvUpload;
