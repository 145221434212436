import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth';
import { Loader } from 'rsuite';
import LoginScreen from '../../components/Login/Screen';
import app from '../../firebase';
import { setPassword, setUser } from '../../actions/authActions';
import { LOGIN, LOGOUT, SET_PASSWORD } from '../../lib/constants';

const LoginContainer = ({ setUser, setPassword }) => {
  const [error, setError] = useState({ error: false });
  const [loading, setLoading] = useState(true);

  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, () => {
      setLoading(false);
      setUser('', LOGOUT);
      setPassword('', LOGOUT);
      // if (user) setUser(user.email, LOGIN);
    });
  }, []);

  const onSubmit = async (email, password) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      setUser(response.user.email, LOGIN);
      setPassword(password, SET_PASSWORD);
    } catch (error) {
      setError({ error: true });
    }
  };

  if (loading) return <Loader backdrop center size="lg" content="Loading..." />;
  return <LoginScreen onSubmit={onSubmit} error={error} />;
};

LoginContainer.propTypes = {
  setUser: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = { setUser, setPassword };

export default connect(null, mapDispatchToProps)(LoginContainer);
