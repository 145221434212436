import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import Navbar from '../../components/Navbar';
import { setPassword, setUser } from '../../actions/authActions';
import { LOGOUT } from '../../lib/constants';
import app from '../../firebase';

const NavbarContainer = ({ setUser }) => {
  const auth = getAuth(app);
  const logoutHandler = () => {
    auth
      .signOut()
      .then(() => {
        setUser('', LOGOUT);
        setPassword('', LOGOUT);
      })
      .catch((error) => console.log(error));
  };
  return <Navbar logoutHandler={logoutHandler} />;
};

NavbarContainer.propTypes = {
  setUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = { setUser };

export default connect(null, mapDispatchToProps)(NavbarContainer);
