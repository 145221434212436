import { LOGIN, LOGOUT } from '../lib/constants';

const initialState = '';

const userReducer = (user = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return payload;
    case LOGOUT:
      return payload;
    default:
      return user;
  }
};

export default userReducer;
