import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es';

const Logo = () => {
  useEffect(() => {
    anime({
      targets: '#hvLogo path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1800,
      delay: 200,
      direction: 'alternate',
      loop: false,
    });
  }, []);

  const textColor = '#1d3557';

  return (
    <svg
      width="55%"
      height="55%"
      viewBox="0 0 865 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="hvLogo"
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="0.947388"
        y="0.47998"
        width="864"
        height="113"
        fill="black"
      >
        <rect fill="white" x="0.947388" y="0.47998" width="864" height="113" />
        <path d="M83.2754 6.63198V107H70.1714V61.496H19.0514V107H5.94739V6.63198H19.0514V50.696H70.1714V6.63198H83.2754Z" />
        <path d="M175.923 6.63198L143.235 69.128V107H130.131V69.128L97.2985 6.63198H111.843L136.611 57.464L161.379 6.63198H175.923Z" />
        <path d="M256.848 36.008C256.848 44.36 253.968 51.32 248.208 56.888C242.544 62.36 233.856 65.096 222.144 65.096H202.848V107H189.744V6.63198H222.144C233.472 6.63198 242.064 9.36798 247.92 14.84C253.872 20.312 256.848 27.368 256.848 36.008ZM222.144 54.296C229.44 54.296 234.816 52.712 238.272 49.544C241.728 46.376 243.456 41.864 243.456 36.008C243.456 23.624 236.352 17.432 222.144 17.432H202.848V54.296H222.144Z" />
        <path d="M286.239 17.288V50.84H322.815V61.64H286.239V96.2H327.135V107H273.135V6.48798H327.135V17.288H286.239Z" />
        <path d="M399.811 107L375.907 65.96H360.067V107H346.963V6.63198H379.363C386.947 6.63198 393.331 7.92798 398.515 10.52C403.795 13.112 407.731 16.616 410.323 21.032C412.915 25.448 414.211 30.488 414.211 36.152C414.211 43.064 412.195 49.16 408.163 54.44C404.227 59.72 398.275 63.224 390.307 64.952L415.507 107H399.811ZM360.067 55.448H379.363C386.467 55.448 391.795 53.72 395.347 50.264C398.899 46.712 400.675 42.008 400.675 36.152C400.675 30.2 398.899 25.592 395.347 22.328C391.891 19.064 386.563 17.432 379.363 17.432H360.067V55.448Z" />
        <path d="M517.516 6.63198L479.644 107H464.524L426.652 6.63198H440.62L472.156 93.176L503.692 6.63198H517.516Z" />
        <path d="M544.989 17.288V50.84H581.565V61.64H544.989V96.2H585.885V107H531.885V6.48798H585.885V17.288H544.989Z" />
        <path d="M658.561 107L634.657 65.96H618.817V107H605.713V6.63198H638.113C645.697 6.63198 652.081 7.92798 657.265 10.52C662.545 13.112 666.481 16.616 669.073 21.032C671.665 25.448 672.961 30.488 672.961 36.152C672.961 43.064 670.945 49.16 666.913 54.44C662.977 59.72 657.025 63.224 649.057 64.952L674.257 107H658.561ZM618.817 55.448H638.113C645.217 55.448 650.545 53.72 654.097 50.264C657.649 46.712 659.425 42.008 659.425 36.152C659.425 30.2 657.649 25.592 654.097 22.328C650.641 19.064 645.313 17.432 638.113 17.432H618.817V55.448Z" />
        <path d="M769.354 35.72C766.57 29.864 762.538 25.352 757.258 22.184C751.978 18.92 745.834 17.288 738.826 17.288C731.818 17.288 725.482 18.92 719.818 22.184C714.25 25.352 709.834 29.96 706.57 36.008C703.402 41.96 701.818 48.872 701.818 56.744C701.818 64.616 703.402 71.528 706.57 77.48C709.834 83.432 714.25 88.04 719.818 91.304C725.482 94.472 731.818 96.056 738.826 96.056C748.618 96.056 756.682 93.128 763.018 87.272C769.354 81.416 773.05 73.496 774.106 63.512H734.074V52.856H788.074V62.936C787.306 71.192 784.714 78.776 780.298 85.688C775.882 92.504 770.074 97.928 762.874 101.96C755.674 105.896 747.658 107.864 738.826 107.864C729.514 107.864 721.018 105.704 713.338 101.384C705.658 96.968 699.562 90.872 695.05 83.096C690.634 75.32 688.426 66.536 688.426 56.744C688.426 46.952 690.634 38.168 695.05 30.392C699.562 22.52 705.658 16.424 713.338 12.104C721.018 7.68798 729.514 5.47998 738.826 5.47998C749.482 5.47998 758.89 8.11998 767.05 13.4C775.306 18.68 781.306 26.12 785.05 35.72H769.354Z" />
        <path d="M818.505 17.288V50.84H855.081V61.64H818.505V96.2H859.401V107H805.401V6.48798H859.401V17.288H818.505Z" />
      </mask>
      <path
        d="M83.2754 6.63198V107H70.1714V61.496H19.0514V107H5.94739V6.63198H19.0514V50.696H70.1714V6.63198H83.2754Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M175.923 6.63198L143.235 69.128V107H130.131V69.128L97.2985 6.63198H111.843L136.611 57.464L161.379 6.63198H175.923Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M256.848 36.008C256.848 44.36 253.968 51.32 248.208 56.888C242.544 62.36 233.856 65.096 222.144 65.096H202.848V107H189.744V6.63198H222.144C233.472 6.63198 242.064 9.36798 247.92 14.84C253.872 20.312 256.848 27.368 256.848 36.008ZM222.144 54.296C229.44 54.296 234.816 52.712 238.272 49.544C241.728 46.376 243.456 41.864 243.456 36.008C243.456 23.624 236.352 17.432 222.144 17.432H202.848V54.296H222.144Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M286.239 17.288V50.84H322.815V61.64H286.239V96.2H327.135V107H273.135V6.48798H327.135V17.288H286.239Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M399.811 107L375.907 65.96H360.067V107H346.963V6.63198H379.363C386.947 6.63198 393.331 7.92798 398.515 10.52C403.795 13.112 407.731 16.616 410.323 21.032C412.915 25.448 414.211 30.488 414.211 36.152C414.211 43.064 412.195 49.16 408.163 54.44C404.227 59.72 398.275 63.224 390.307 64.952L415.507 107H399.811ZM360.067 55.448H379.363C386.467 55.448 391.795 53.72 395.347 50.264C398.899 46.712 400.675 42.008 400.675 36.152C400.675 30.2 398.899 25.592 395.347 22.328C391.891 19.064 386.563 17.432 379.363 17.432H360.067V55.448Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M517.516 6.63198L479.644 107H464.524L426.652 6.63198H440.62L472.156 93.176L503.692 6.63198H517.516Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M544.989 17.288V50.84H581.565V61.64H544.989V96.2H585.885V107H531.885V6.48798H585.885V17.288H544.989Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M658.561 107L634.657 65.96H618.817V107H605.713V6.63198H638.113C645.697 6.63198 652.081 7.92798 657.265 10.52C662.545 13.112 666.481 16.616 669.073 21.032C671.665 25.448 672.961 30.488 672.961 36.152C672.961 43.064 670.945 49.16 666.913 54.44C662.977 59.72 657.025 63.224 649.057 64.952L674.257 107H658.561ZM618.817 55.448H638.113C645.217 55.448 650.545 53.72 654.097 50.264C657.649 46.712 659.425 42.008 659.425 36.152C659.425 30.2 657.649 25.592 654.097 22.328C650.641 19.064 645.313 17.432 638.113 17.432H618.817V55.448Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M769.354 35.72C766.57 29.864 762.538 25.352 757.258 22.184C751.978 18.92 745.834 17.288 738.826 17.288C731.818 17.288 725.482 18.92 719.818 22.184C714.25 25.352 709.834 29.96 706.57 36.008C703.402 41.96 701.818 48.872 701.818 56.744C701.818 64.616 703.402 71.528 706.57 77.48C709.834 83.432 714.25 88.04 719.818 91.304C725.482 94.472 731.818 96.056 738.826 96.056C748.618 96.056 756.682 93.128 763.018 87.272C769.354 81.416 773.05 73.496 774.106 63.512H734.074V52.856H788.074V62.936C787.306 71.192 784.714 78.776 780.298 85.688C775.882 92.504 770.074 97.928 762.874 101.96C755.674 105.896 747.658 107.864 738.826 107.864C729.514 107.864 721.018 105.704 713.338 101.384C705.658 96.968 699.562 90.872 695.05 83.096C690.634 75.32 688.426 66.536 688.426 56.744C688.426 46.952 690.634 38.168 695.05 30.392C699.562 22.52 705.658 16.424 713.338 12.104C721.018 7.68798 729.514 5.47998 738.826 5.47998C749.482 5.47998 758.89 8.11998 767.05 13.4C775.306 18.68 781.306 26.12 785.05 35.72H769.354Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M818.505 17.288V50.84H855.081V61.64H818.505V96.2H859.401V107H805.401V6.48798H859.401V17.288H818.505Z"
        stroke={textColor}
        strokeWidth="10"
        mask="url(#path-1-outside-1)"
      />
    </svg>
  );
};

export default Logo;
