import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import Logo from './Logo';
import Layout from '../common/Layout';
import CsvContainer from '../../containers/csv';

const HomeScreen = () => {
  const content = (
    <>
      <Grid fluid style={{ marginTop: '5%' }}>
        <Row style={{ textAlign: 'center' }}>
          <Col md={12} mdOffset={6} xs={20} xsOffset={2}>
            <Logo />
          </Col>
        </Row>
        <Row style={{ textAlign: 'center', marginTop: '5%' }}>
          <Col md={12} mdOffset={6} xs={20} xsOffset={2}>
            <CsvContainer />
          </Col>
        </Row>
      </Grid>
    </>
  );

  return <Layout content={content} />;
};

export default HomeScreen;
