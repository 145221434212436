import React from 'react';
import { Message } from 'rsuite';
import PropTypes from 'prop-types';

const SuccessMessage = ({ batchId }) => {
  return (
    <Message
      type="success"
      title="Upload successful"
      showIcon
      style={{ marginBottom: '1%' }}
      className="alert"
    >
      File uploaded successfully! ID for this batch is {batchId}
    </Message>
  );
};

SuccessMessage.propTypes = {
  batchId: PropTypes.string.isRequired,
};

export default SuccessMessage;
